<template>
  <a-layout style="flex: 1 0 auto;">
      <a-modal v-model="ringVisible" class="demo-modal" title="救援电话" @ok="handleOk">
        <p style="font-size: 35px">{{ caller }}呼入救援电话，是否立即处理？</p>
      </a-modal>
    <div v-if="!fullScreen">
      <a-layout-sider v-model="collapsed" :trigger="null" collapsible width="208" class="main-aside" style="flex: 1 0 auto;">
        <div class="logo">
<!--          <img src="~@/assets/image/logo.png">-->
          <img :src="logourl">
          <span v-show="!collapsed">电梯安全公共信息服务平台</span>
        </div>
        <a-menu
          mode="inline"
          theme="dark"
          class="aside-menu"
          :inline-collapsed="collapsed"
          :selected-keys="selectedKeys"
          :style="{ borderRight: 0, height: 'calc(100vh - 91px)', overflow: 'auto' }" >
          <template v-for="item in asideMenuTree">
            <sub-menu v-if="item.children && item.children.length" :key="'info1-'+item.menuid" :menu-info="item" @click-menu-item="clickMenuItem"></sub-menu>
            <a-menu-item v-else :key="'info2-'+item.menuid" @click="clickMenuItem(menuItem)">
              <a-icon v-if="item.menupic" :type="item.menupic" />
              <span>{{item.menuname}}</span>
            </a-menu-item>
          </template>
        </a-menu>
        <div style="height: 40px;"></div>
        <div class="trigger-bottom" :class="collapsed?'trigger-collapsed':''">
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => ($store.commit('main/setCollapsed', !collapsed))"
          />
        </div>
      </a-layout-sider>
    </div>
    <a-layout style="overflow: hidden;position: relative;">
      <a-layout-header class="header" v-if="!fullScreen">
        <div class="tab-group">
          <page-tabs size="large" :active-key="selectedTab" @change="tabChange" @edit="tabEdit">
            <a-tab-pane v-for="(item, index) in tabs" :key="item.id" :tab="item.name" :closable="index>0"></a-tab-pane>
          </page-tabs>
        </div>
        <div>
          <a-radio-group name="radioGroup"  v-model="playtype" :default-value="0" @change="onChange" required>
            <a-radio :value="0">
              FLV
            </a-radio>
            <a-radio :value="1">
              VLC
            </a-radio>
          </a-radio-group>
        </div>
        <a-modal v-model="play_show" @ok="handlePlaytype">
          <p style="font-size: 35px">请问您是否需要切换播放方式</p>
        </a-modal>
        <div class="header-right" v-if="show">
          <a-form-model v-if="phoneLoginVisible==true" ref="queryForm" layout="inline" class="query-form" style="float: left;">
            <a-form-model-item label="账号" prop="user">
              <a-input v-model="user" placeholder="" disabled="disabled" style="width: 80px"></a-input>
            </a-form-model-item>
            <a-form-model-item label="密码" prop="password">
              <a-input type="password" v-model="password" disabled="disabled" placeholder="" style="width: 80px"></a-input>
            </a-form-model-item>
            <a-form-model-item label="坐席号" prop="login_ext_no">
              <a-input v-model="login_ext_no" disabled="disabled" placeholder="" style="width: 80px"></a-input>
            </a-form-model-item>
            <a-button type="danger" @click="login" style="margin-top: 9px">坐席登录</a-button>
          </a-form-model>
          <a-form-model v-else-if="phoneLoginVisible==false" ref="queryForm" layout="inline" class="query-form" style="float: right;">
            <a-form-model-item label="当前坐席" prop="liftrescue">
              <a-input v-model="login_ext_no" placeholder="" disabled="disabled" style="width: 100px"></a-input>
            </a-form-model-item>
            <a-form-model-item label="坐席状态" prop="ext_status">
              <a-tag :color="ext_status==='在线'?'green':'red'">{{ext_status}}</a-tag>
<!--              <a-input v-model="ext_status" placeholder="" disabled="disabled"></a-input>-->
            </a-form-model-item>
            <a-button v-if="agent_status" type="primary" @click="agent_state('break1')" style="margin-top: 9px;margin-left: 5px">示忙</a-button>
            <a-button v-if="agent_status==false" type="primary" @click="agent_state('ready')" style="margin-top: 9px;margin-left: 5px">示闲</a-button>
            <a-button type="danger" @click="logout" style="margin-left: 5px;margin-top: 9px">坐席登出</a-button>
          </a-form-model>
        </div>
        <div class="header-right">
          <a-dropdown>
            <div class="user-avatar">
              <a-avatar icon="user" />
              <span style="display: inline-block; margin-left: 10px;">{{$store.state.userInfo.username}}</span>
            </div>
            <a-menu slot="overlay" @click="avatarClick">
              <a-menu-item key="user">个人中心</a-menu-item>
              <a-menu-item key="download" @click="download('vlc-3.0.16-win32.exe','vlc-3.0.16-win32.exe')">插件下载</a-menu-item>
              <a-menu-item key="logout">退出登录</a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout-content
        :style="{ position: 'relative', padding: fullScreen?'0':'50px 0 0 0', minHeight: '280px', height: '100vh', flexShrink: '0', flexGrow: '0', overflow: 'auto'}" @scroll="
        pageScroll"
      >
        <transition name="page-move">
          <keep-alive :include="$store.state.keepAliveComponents">
            <router-view />
          </keep-alive>
        </transition>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import {getUserByUserid, logout} from 'A/login'
import PageTabs from 'C/PageTabs'
import { Menu } from 'ant-design-vue';
import {getCache, removeCache} from 'U'
import callCenter from "C/modals/callCenter";
import {addUntreatedCall} from "A/thjlcx";
import {addUser, editUser} from "A/xtpz";
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.menuid" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <a-icon v-if="menuInfo.menupic" :type="menuInfo.menupic" />
          <span>{{ menuInfo.menuname }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <sub-menu v-if="item.children && item.children.length" :key="item.menuid" :menu-info="item" @click-menu-item="clickMenuItem" />
          <a-menu-item v-else :key="item.menuid" @click="clickMenuItem(item)">
            <a-icon v-if="item.menupic" :type="item.menupic" />
            <span>{{ item.menuname }}</span>
          </a-menu-item>
        </template>
      </a-sub-menu>
    `,
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    // Cannot overlap with properties within Menu.SubMenu.props
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    clickMenuItem(item) {
      this.$emit('click-menu-item', item);
    },
  }
};
export default {
  data() {
    return {
      show:false,
      logourl:'https://esp.u-lake.com/file/logo/admin-logo.png',
      playtype:0,
      userdepid:'',
      play_show:false,
      userid:'',
      //通话
      agent_status:true,
      ext_status:"在线",
      phoneLoginVisible:true,
      ringVisible:false,
      caller:'',
      usertype:'',
      isAble:'',
      opcall: '',
      msg_div: '',
      user: '',
      password: '',
      login_ext_no: '',
      login_queue: '',
      stat_div: '',
      dia_num: '',
    };
  },
  components: {
    SubMenu,
    PageTabs
  },
  mounted:function(){
    window.addEventListener("beforeunload", function(e) {
      sessionStorage.setItem('phoneLoginVisible','true');
    });
    if(this.usertype==6){
      console.log("执行呼叫中心登录程序")
      this.timer = setInterval(this.login, 180000);
      // this.timer = setInterval(this.logout, 360000);
    }
  },
  computed: {
    ...mapState({
      collapsed: state => state.main.collapsed,
      // menuList: state => state.menuList,
      selectedKeys: state => state.main.selectedKeys,
      tabs: state => state.main.tabs,
      selectedTab: state => state.main.selectedTab,
      fullScreen: state => state.main.fullScreen,
    }),
    ...mapGetters(['asideMenuTree'])
  },
  created() {
    this.init();
    this.alarmModal();
  },
  methods: {
    handlePlaytype(){
      this.play_show=false
      this.modifyPlaytype(this.playtype,this.usertype,this.userdepid)
      logout().then(res=>{
        removeCache('logininfo', true);
        this.$store.commit('main/resetTabs');
        this.$store.commit('setToken');
        this.$router.push('/login')
      }).catch(err => {
        removeCache('logininfo', true);
        this.$store.commit('main/resetTabs');
        this.$store.commit('setToken');
        this.$router.push('/login')
      })
    },
    onChange(e) {
      this.playtype=e.target.value
      this.play_show=true
    },
    download(filename,name){
      let a = document.createElement('a');
      a.href = './'+filename;
      //路径中'/'为根目录，即index.html所在的目录
      a.download = name;
      a.click();
    },
    handleOk() {
      this.ringVisible = false;
      this.$router.push({
        path:'/emergency',
        query: {
          caller:this.caller,
        }
      })
    },
    alarmModal(){
      var that=this;
      callCenter.methods.bind_event(callCenter.methods.initPhone())
      callCenter.methods.initPhone().lib.event("ring").unsubscribe().subscribe(function (ext) {
        console.log("接通电话")
        console.log(ext)
        if(ext.agent_state=='Receiving'){
          that.ringVisible=true;
          that.caller=ext.peer;
        }
        let params = {
          calltime:new Date(),
          phonenum:ext.peer,
          calltype:ext.direction=='in'?0:1
        }
        console.log(params)
        addUntreatedCall(params).then(res=>{
          if(res&&res.returncode=='0'){
            that.$message.success("已成功添加未处理来电")
          }
        })
        $("#dia").attr("disabled", true);
        $("#dia_num").attr("disabled", true);
        $("#hangup").attr("disabled", false);
        $("#answer").attr("disabled", false);
        $("#spy").attr("disabled", true);
        $("#breakin").attr("disabled", true);
        $("#breakin2threeway").attr("disabled", true);
        $("#blind_transfer").attr("disabled", false);
        console.log("电话振铃了[此处完成弹屏]，来电号码" + ext.peer + "，IVR按键随路：" + ext.call_info + "，话单唯一编号:" + ext.cdr_uuid);
      });
      callCenter.methods.initPhone().lib.event("busy").unsubscribe().subscribe(function (ext) {
        // this.stat_div.html("忙线");
        console.log("挂断电话")
        console.log(ext)
        let params = {
          calltime:new Date,
          phonenum:ext.peer,
          calltype:ext.direction=='in'?0:1
        }
        addUntreatedCall(params)
        console.log("电话接通了[如果缺失ring事件，那么应该在answer中完成弹屏]，来电号码" + ext.peer + "，IVR按键随路：" + ext.call_info + "，话单唯一编号:" + ext.cdr_uuid);
        $("#dia").attr("disabled", true);
        $("#dia_num").attr("disabled", true);
        $("#hangup").attr("disabled", false);
        $("#answer").attr("disabled", true);
        $("#hold").attr("disabled", false);
        $("#unhold").attr("disabled", false);
        $("#tranfer").attr("disabled", false);
        $("#tranfer_queue").attr("disabled", false);
        $("#switch_threeway").attr("disabled", false);
        $("#cancel_transfer").attr("disabled", false)
        $("#xfer2threeway").attr("disabled", false)
        $("#blind_transfer").attr("disabled", false);
      });
    },
    //呼叫中心登录
    login() {
      try {
        this.phoneLoginVisible=false;
        sessionStorage.setItem('phoneLoginVisible','false');
        this.initPhone();
        this.opcall.lib.startup();
      } catch (err) {
        this.isAble=true;
        this.phoneLoginVisible=true;
        alert(err);
      }
    },
    //登出
    logout() {
      this.phoneLoginVisible=true;
      sessionStorage.setItem("phoneLoginVisible",'true');
      this.opcall.lib.stop();
    },
    //改变坐席状态
    agent_state(status) {
      if(status=="ready"){
        this.agent_status=true
        this.ext_status="在线"
      }else{
        this.agent_status=false;
        this.ext_status="离线"
      }
      this.opcall.cli.adv_agent_status('-', status, '', function (data) {
        console.log(data.message);
      });
    },
    //通话中心初始化
    initPhone() {
      let logininfo = getCache('logininfo', true);
      if(logininfo) {
        //将IP和账号密码替换为实际的IP和账号、密码
        let cti_config = {
          debug: true,
          baseurl: "http://183.214.195.130:85/atstar/client.php",
          user: logininfo.phonename,
          password: logininfo.phonepassword,
          login_ext_no: logininfo.phonextnum
        };
        this.opcall = new call(cti_config);
        this.msg_div = $("#msg_div");
        this.stat_div = $("#stat_div");
        // this.bind_event(this.opcall);
      }
    },
    init() {
      let logininfo = getCache('logininfo', true);
      if(logininfo) {
        this.userid=logininfo.usersid
        this.usertype=logininfo.usertype
        this.playtype=logininfo.playtype
        this.user=logininfo.phonename
        this.password=logininfo.phonepassword
        this.login_ext_no=logininfo.phonextnum
        this.userdepid=logininfo.userdepid
        if(logininfo.usertype==6){
          this.show=true
        }
      }
      this.getLogo();
      // this.setMenuActived();
    },
    getLogo(){
      let params = {
        userid: this.userid,
      }
      getUserByUserid(params).then(res => {
        if (res && res.returncode == '0') {
          if(res.item.logourl!=null&&res.item.logourl!=""){
            this.logourl="https://esp.u-lake.com/file/"+res.item.logourl;
          }
          if(res.item.companyname!=null&&res.item.companyname!=""){
            this.companyname=res.item.companyname;
          }
        }
      })
    },
    setMenuActived() {
      this.$store.commit('main/setSelectedKeys', [this.$route.name]);
    },
    clickMenuItem(menuItem) {
      if(menuItem.menuurl) {
        this.$router.push({name: menuItem.menuurl})
      }
    },
    tabChange(key) {
      this.$router.push({name: key})
    },
    tabEdit(key, action) {
      if(action == 'remove') {
        // console.log(this.$route.name)
        if(key == 'elevatorArchives' && this.$route.name == 'timeMonitoring') {
          this.$router.push({name: 'home'})
        }
        if((key == 'enquipmentList' || key == 'equipmentGroup') && this.$route.name == 'paramsConfig') {
          this.$router.push({name: 'home'})
        }
        if(this.selectedTab == key) {
          this.$router.push({name: 'home'})
        }
        this.$store.commit('main/delTab', key)
      }
    },
    avatarClick({key}) {
      if(key == 'logout') {
        logout().then(res => {
          // if(res && res.returncode == '0') {
            removeCache('logininfo', true);
            this.$store.commit('main/resetTabs');
            this.$store.commit('setToken');
            this.$router.push({
              name:'login',
              params:{
                flag:true
              }
            })
          // }else {
          //   this.$message.error(res.errormsg||'操作失败');
          // }
        }).catch(err => {
          removeCache('logininfo', true);
          this.$store.commit('main/resetTabs');
          this.$store.commit('setToken');
          this.$router.push({
            name:'login',
            params:{
              flag:true
            }
          })
        })
      }else if(key == 'user') {
        this.$router.push({name: 'userCenter'})
      }else if(key=='download'){
      }
    },
    pageScroll(event) {
      // console.log(event)
      let ele = event.target || event.srcElement;
      // console.log(ele.scrollTop, ele.scrollLeft)
      this.$store.commit('setPageScrollInfo', {top: ele.scrollTop, left: ele.scrollLeft});
    },
    modifyPlaytype(playtype,usertype,userdepid){
      let params = {
        userid:this.userid,
        status:1,
        usertype:usertype,
        playtype:playtype,
        userdepid:userdepid
      }
        editUser(params).then(res=>{
        })
      }
    }
};
</script>
<style lang="scss" scoped>
.main-aside {
  position: relative;
  z-index: 2;
  box-shadow: 2px 0px 6px 0px rgba(0, 21, 41, 0.35);
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  img {
    height: 30px;
  }
  span {
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
    color: white;
    white-space: nowrap;
  }
}
.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  height: 50px;
  background: #fff;
  padding: 0;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
}
.trigger {
  font-size: 18px;
  line-height: 40px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  flex-shrink: 0;
}
.trigger:hover {
  color: #1890ff;
}
.tab-group {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}
.header-right {
  flex-shrink: 0;
  line-height: 50px;
  padding: 0 26px;
}
.trigger-bottom {
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 208px;
  height: 41px;
  color: white;
  border-top: solid 1px #131b24;
  background-color: #001529;
  transition: width ease 0.2s;
}
.trigger-collapsed {
  width: 80px;
}
.page-move-enter-active, .page-move-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform .3s;
}
.page-move-enter {
  transform: translateX(-100%)
}
.page-move-leave-to {
  transform: translateX(100%)
}
.demo-modal{
  .ant-modal-title{
    color: blue;
    font-size: 20px;
    text-align: center;
  }
  .ant-modal-header {
    padding: 60px 20px;
    border-bottom: 0;
    background-color: blue;
  }
  .ant-modal-body {
    padding-bottom: 2px;
  }
}
</style>
